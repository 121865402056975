/* Custom script for DisneyHolidays.xx framework v2.0.0 */
/* Includes all brands (WDW/DLP/DCL) on desktop only
/* Author: Lindsay Prahalad/Tom Nelson, October 2015 */

// -------------------------------------------------------------------------------------------------------------------

// Global variables

// Define which framework we are using
var desktop = true;
var mobile = false;

// -------------------------------------------------------------------------------------------------------------------

// Run billboard carousel on load; desktop version uses fade transitions
var initialiseBillboard = function(page) {

	// Load slick library and start relevant billboard	
	if(page === "contentPage") {
		startBillboardOnContentPage();
	} else {
		startBillboard();		
	}
	
	// Pause carousel on click of next/previous or QQ interaction
	$('.billboard button').click(function() { 
		billboard.slick('slickPause'); 
	});
	$('#panel').mousedown(function() { 
		billboard.slick('slickPause'); 
	}); 
	$('#panel select').change(function() { 
		billboard.slick('slickPause'); 
	});
};

// -------------------------------------------------------------------------------------------------------------------

// Float itinerary panel on bookflow pages */
var itineraryTop,footerTop,maxY,bodyHeight,itineraryHeight,float;

// Setup itinerary
function itinerary() {
// Check positions 
itineraryTop = $('#itinerary').offset().top
footerTop = $('.footer').offset().top
// Check heights
bodyHeight = $('.body').height();
itineraryHeight = $('#itinerary').height();
// Difference
maxY = footerTop - itineraryHeight;
// Don't float by default
float = false;
// Only float if body is taller than itinerary
if (bodyHeight > itineraryHeight) { $('#itinerary').css('position','absolute'); float = true; }
// Initialise; fixes issues with reload/back in Firefox
follow();
}

// On scroll float if required
function follow() {	
if (float) {
// Follow scroll
var y = $(this).scrollTop();
// If scroll is greater than itinerary start
if (y > itineraryTop) {
// If scroll is less than maximum offset
if (y < maxY) {
// Fix panel
$('#itinerary').addClass('fixed').removeAttr('style'); }
// Follow scroll 
else
{ $('#itinerary').removeClass('fixed').css({ position:'absolute', top:(maxY - itineraryTop) + 'px' }); }
}
// Switch back to position:absolute
else { $('#itinerary').removeClass('fixed'); }
};
}

// -------------------------------------------------------------------------------------------------------------------

/* Sitewide */

// Prevent 'tel' link from firing on non-mobile platforms
$('a[href^="tel"]').click(function(event) { event.preventDefault(); });

// Enable buttons in IE via passthrough click to <a> behind; CSS handles this for non-IE where pointer-events are supported
$('a.button > input.button').click(function() { window.location.href = $(this).parent().attr('href'); });

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function() {

// -------------------------------------------

// Initialise tooltips
if ($('.tooltip').length) {
$('.tooltip').tooltip({
items:'.tooltip',
content: function() { return $(this).find('span').text(); },
position: { my:'bottom', at:'top-10', collision:'flipfit', using:function(position,feedback ) { $(this).addClass(feedback.vertical).css(position); }}
});
}

// -------------------------------------------

// If bookflow page then itinerary panel should follow page scroll
if ($('#itinerary').length) {
// Setup itinerary variables; 0.25s delay to ensure page has finished rendering
setTimeout(function() { itinerary(); },250);
// Listen for page scroll
$(window).scroll(function() { follow(); });
};

// -------------------------------------------

// Close cookie banner on 'Continue'
$('#cookie a.continue').click(function(event) { event.preventDefault(); $('#cookie').fadeOut(); });

// -------------------------------------------

// Clicking anywhere on an offer navigates to url, not just the link
$('section.offers .offer').click(function() {
	window.location = $(this).find('a').attr('href'); 
});

});
